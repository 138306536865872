.popover{
    font-size: $font-size-base;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
    border: none;
    line-height: 1.7;
    max-width: 240px;

    &.bs-popover-top .arrow:before,
    &.bs-popover-left .arrow:before,
    &.bs-popover-right .arrow:before,
    &.bs-popover-bottom .arrow:before,{
        border-top-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
    }

    .popover-header{
        color: $default-color-opacity;
        font-size: $font-size-base;
        text-transform: capitalize;
        font-weight: $font-weight-semi;
        margin: 0;
        margin-top: 5px;
        border: none;
        background-color: transparent;
    }

    &:before{
        display: none;
    }

    &.bs-tether-element-attached-top:after{
        border-bottom-color:$white-color;
        top: -9px;
    }

    &.popover-primary{
        @include popover-color($primary-color, $white-color);
    }

    &.popover-info{
        @include popover-color($info-color, $white-color);
    }

    &.popover-warning{
        @include popover-color($warning-color, $white-color);
    }

    &.popover-danger{
        @include popover-color($danger-color, $white-color);
    }

    &.popover-success{
        @include popover-color($success-color, $white-color);
    }
}


// .tooltip{
//     &.bs-tooltip-right .arrow:before{
//         border-right-color:$white-color;
//     }

//     &.bs-tooltip-top .arrow:before{
//         border-top-color:$white-color;
//     }

//     &.bs-tooltip-bottom .arrow:before{
//         border-bottom-color:$white-color;
//     }

//     &.bs-tooltip-left .arrow:before{
//         border-left-color:$white-color;
//     }
// }

// .tooltip-inner{
//     padding: $padding-base-vertical $padding-base-horizontal;
//     min-width: 130px;
//     background-color: $white-color;
//     font-size: $font-size-base;
//     color: inherit;
//     box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
// }
