@import "~@ng-select/ng-select/themes/default.theme.css";

/* archwizard */
@import '../node_modules/angular-archwizard/archwizard.css';

/* ngx-datatable */
@import url("../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css");
@import url("../node_modules/@swimlane/ngx-datatable/assets/icons.css");
@import url("../src/assets/css/ngx-datatable-custom-styles.css");
/* @import '~quill/dist/quill.core.css'; */
/* @import '~quill/dist/quill.bubble.css'; */
/* @import '~quill/dist/quill.snow.css'; */
.login-page .content .container{
    margin-top: 0px;
}
.bodyColumn::-webkit-scrollbar,

.body-card::-webkit-scrollbar,
.fixTableHead::-webkit-scrollbar,
.accordion_fields::-webkit-scrollbar,
.commentBox::-webkit-scrollbar,
.dropdownNotifications::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.wrapper-table::-webkit-scrollbar,
html::-webkit-scrollbar {
	width: 8px !important;
	height: 8px !important;
	border-radius: 7px !important;
}

.bodyColumn::-webkit-scrollbar-track,
.body-card::-webkit-scrollbar-track,
.fixTableHead::-webkit-scrollbar-track,
.accordion_fields::-webkit-scrollbar-track,
.commentBox::-webkit-scrollbar-track,
.dropdownNotifications::-webkit-scrollbar-track,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track,
.wrapper-table::-webkit-scrollbar-track ,
html::-webkit-scrollbar-track {
	background: #aaa !important;
	border: 1px solid #aaa !important;
	border-radius: 7px !important;
	
}

.bodyColumn::-webkit-scrollbar-thumb,
.body-card::-webkit-scrollbar-thumb,
.fixTableHead::-webkit-scrollbar-thumb,
.accordion_fields::-webkit-scrollbar-thumb,
.commentBox::-webkit-scrollbar-thumb,
.dropdownNotifications::-webkit-scrollbar-thumb,
.ag-body-horizontal-scroll-viewportl::-webkit-scrollbar-thumb,
.wrapper-table::-webkit-scrollbar-thumb,
html::-webkit-scrollbar-thumb {
	background: #3e50b4 !important;
	border: solid 1px #9368E9 !important;
	border-radius: 7px !important;
}

.navbarColor{
    background-color: #262626;
}

.logo{
    height: 61px;
    background-color: #f4f3ef;
    border-right: 1px solid #ddd;
}

.logo .logo-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;    
}

.sidebar-wrapper nav li{
    margin-bottom: 30px;
}

.bootstrap-select{
    width: 100% !important;
}

.ng-select .ng-select-container {
    border: 1px solid #9d9d9d !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 40px;
}
button:focus {
    outline: none;
    outline: none;
}

.switch.switch-medium {
    min-width: 70px !important;
}
.card-modal{
    margin-bottom: 0;
}
.card-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.col-actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.nav-tabs .nav-item .nav-link{
    color: #6c757d;
}

.nav-tabs .nav-item .active{
    color: #3e50b4;
}
.bs-datepicker-head {
    background-color: #3e50b4 !important;
}
.badge{
    font-size: 8px !important;
    padding: 0px;
    color: #fff !important;
}
.fa-spinner{
    color: white;
    font-size: 17px;
}

.iconNotifications i{
    font-size: 24px !important;
}
.iconNotifications{
    position: relative;
}
.iconNotifications .badge{
    position: absolute;
    left: -5px;
    top: 0px;
    font-size: 12px !important;
    padding: 3px 3px;
    border-radius: 50%;
    line-height: 10px;
    width: 20px;
    height: 20px;
}
.comiteNotification{
    font-weight: bold;
    color: #f34235;
}

.dropdownNotifications{
    max-width: 450px;
    min-width: 260px;
    overflow: auto;
    max-height: 350px;
}

.notification-item{
    padding: 10px 20px 10px 15px !important;
    text-align: justify;
    white-space: normal !important;
    border-bottom: 1px solid #6c757d;
    cursor: pointer;
}
.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #E3E3E3;
}

.item-cursor {
    cursor: pointer;
}

.iconLink .fa-icon{
    font-size: 22px;
    color: white;
    margin-right: 5px;
}

.linkElement{
    display: flex;
    flex-direction: row;
}

.caret{
    margin: 9px 0px;
}
.flex-col-center-reverse{
    display: flex;
    height: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    margin-left: 10px;
}
.data .card-category{
    font-size:1.2rem;
}

.data .card-title{
    font-size:1.2rem;
}
.gadget-header{
    display: flex;
    flex-direction: row;
}
.gadget-header .title{
    font-size      : 1.2rem;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    margin-left    : 10px;
}
.blue-spinner{
    color: rgba(0, 0, 255, 0.567) !important;
    font-size: 70px !important;
  }


  .fixTableHead {
    overflow-y: auto;
    height: 300px;
  }
  .fixTableHead thead th {
    position: sticky;
    top: -12px;
  }
  .fixTableHead table {
    border-collapse: collapse;
    width: 100%;
  }
  .fixTableHead th,
  .fixTableHead td {
    padding: 8px 15px;
    /* border: 2px solid #529432; */
  }
  .fixTableHead th {
    background: white;
  }

  .gadget-table{
    padding: 7px 7px;
  }
  .diferencia{
    color: red;
    font-size: 3rem;
  }
  

  /* .styledDropdown{
      background-color: red;
  } */

  .styledDropdown .dropdown-item{
    padding: 2px !important;
    font-size: .8rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:180px;
    padding-left: 5px;
  }

  .equipo-item .text{
      color: #fff;
      font-weight: bold;
  }


 .equipoBar{
    width: 90%;
    position: relative;
    height: 100%;
 }

.init{
    background: rgb(165,42,42);
    background: linear-gradient(0deg, rgb(82 45 45) 0%, rgba(38,38,32,1) 100%);
    width: 100%;
    height: 20px;
 }

.fin{
    background: rgb(165,42,42);
    background: linear-gradient(180deg, rgb(82 44 44) 0%, rgba(38,38,32,1) 100%);
    width: 100%;
    height: 20px;
 }

.btn-lideres{
    background-color: rgb(129 0 0);
    /* rgb(129 0 0); */
    /* rgb(194, 0, 0) */
}

 .equipoBar div{
     padding: 0 15px;
 }

 .caret-item:hover{
    /* background-color: blue; */
    background-color: rgba(182,106,106,0.6);
    color: #fff;
    cursor: pointer;

 }
 .caret-item{
     min-height: 100%;
     /* height: 100%; */
     width: 10%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
 }
 .menu-equipo{
    transition: width .5s ease-out;
    width: 0px;
    overflow: hidden;
    background-color: #482e2e;
    position: absolute;
    display: flex;
    flex-direction: row;
    z-index: 1;
 }
 /* #9e3939 */
 .menu-equipo.active{
    width: 260px;
 }

 .caret-container{
     width: 100%;
     text-align: center;

 }
 .caret-container .caret{
    transform: rotate(90deg);
     position: relative !important;
     right: unset !important;
     top: unset !important;
 }

 .menu-equipo span {
    opacity: 0;
    transition: opacity .3s ease-in-out .3s;
}
.active span{
     opacity: 1;
    /* color: #fff; */
    display:inline;
}
.head-icon{
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 3px !important;
}

  .center-check{
    margin-left: 8px;
    margin-top: 9px; 
  }
