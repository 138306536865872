.pointer {
  cursor: pointer;
}

.datatable-scroller {
  width: 100% !important;
}

.datatable-body-cell {
  margin-left: 3px !important;
}

.datatable-footer {
  background: #ffffff !important;
}

.ngx-datatable.bootstrap {
  font-size: 13px !important;
  /* font-weight: 400 !important; */
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif !important;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  font-weight: 700 !important;
}

.ngx-datatable.bootstrap .datatable-footer {
  color: #212529 !important;
  border-top: 1px solid #dee2e6 !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #3e50b4 !important;
  border-color: #3e50b4 !important;
  color: #ffffff !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: rgb(255, 255, 255) !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  border-top: 1px solid #dee2e6 !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  border-radius: 30px !important;
  color: #000000 !important;
}

.ngx-datatable.bootstrap .btn, .navbar .navbar-nav > a.btn {
  margin-top: 0px;
}

.fb{
  font-weight: bold !important;
}

.display-n{
  display: none;
}

.text-right{
  text-align: right;
}